<template>
  <section id="add-workShift">
    <div class="add-student-body px-2">
      <div
          class="w-100 py-2"
      >
        <b-card>
          <h2 class="brand-text text-primary text-main-header">
            {{ isUpdate? 'Cập nhật' : 'Thêm mới' }} Qr code
          </h2>
          <validation-observer  ref="qrCodeDTO">
            <b-row class="col-md-12 col-12 mt-5">
              <b-col class="col-md-6 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                      #default="{ errors }"
                      name="Chuỗi mã hóa QR"
                      rules="required"
                  >
                    <label
                        class="label-add-work-shift"
                        for="address"
                    >Chuỗi mã hóa QR<span class="text-danger"> (*)</span></label>
                    <input
                        id="address"
                        v-model="qrCodeDTO.qrCode"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập chuỗi mã hóa"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-6 col-12 mt-3">
                <div class="form-group">
                  <b-form-checkbox
                      v-model="qrCodeDTO.status"
                      class="label-add-work-shift bfcheck"
                  >Trạng thái hoạt động
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12 mt-2" v-if="qrCodeDTO.qrCode">
              <b-col class="col-md-6 col-12">
                  <label class="vs-input--label">Mã QR</label>
                  <div id="qr-code" class="mt-2 ml-16">
                    <div ref="capture" class="qr-code-render">
                      <VueQRCodeComponent class="ml-5" :text="qrCodeDTO.qrCode" error-level="H"></VueQRCodeComponent>
                    </div>
                    <b-button size="small" class="btn-download-qr mt-1" @click="exportQRCode"    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                             >Xuất File
                    </b-button>
                  </div>
              </b-col>

            </b-row>
            <div class="button-box-footer float-right mt-1">
              <div class="demo-inline-spacing">
                <b-button
                    v-if="!isUpdate"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="addQR"
                >
                  Thêm mới
                </b-button>
                <b-button
                    v-if="isUpdate"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="addQR"
                >
                  Cập nhật
                </b-button>
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    :to="{ name: 'manage-qr-code'}"
                    variant="outline-danger"
                >
                  Quay lại
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VueQRCodeComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      dir: 'rtl',
      isUpdate: false,
      qrCodeDTO: {},
      idQrCode: null,
    }
  },
  computed: {
  },
  created() {
    const parameters = this.$route.query
    if (parameters.id) {
      this.isUpdate = true
      this.idQrCode = parameters.id
      this.getInfoQR()
    }
  },
  methods: {
    exportQRCode() {
      const src = document.getElementById('qr-code').getElementsByTagName('img')[0].src
      this.downloadBase64File(src, 'QR-Code')
    },
    downloadBase64File(contentBase64, fileName) {
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)
      downloadLink.href = contentBase64
      downloadLink.target = '_self'
      downloadLink.download = fileName
      downloadLink.click()
    },
    getInfoQR() {
      const id = this.$route.query.id
      if (!id) {
        return
      }
      this.$crm.get(`qr-code/find-by-id/${this.idQrCode}`).then(response => {
        if (response.data) {
          this.qrCodeDTO = response.data
          this.qrCodeDTO.status = true ? this.qrCodeDTO.status === 1 : this.qrCodeDTO.status === 0
        }
      })
    },
    addQR() {
      this.$refs.qrCodeDTO.validate().then(success => {
        if (success) {
          this.qrCodeDTO.status = this.qrCodeDTO.status ? 1 : 0
          const url = !this.isUpdate ? 'qr-code/create' : `qr-code/update/${this.idQrCode}`
          this.$crm.post(url, this.qrCodeDTO).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: this.isUpdate ? 'Cập nhật QR Code chấm công thành công' : 'Tạo QR Code chấm công thành công',
              },
            })
            this.$router.push({
              name: 'manage-qr-code',
            })
          })
        }
      })
    },
    confirmDeleteRecord(index) {
      this.selectedQRId = index
      this.$swal({
        title: 'Xác nhận xóa',
        text: 'Bạn có chắc là muốn xóa ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord()
        }
      })
    },
    deleteRecord() {
      this.qrCodeDTO.splice(this.selectedQRId, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/custom/manageQr';
</style>
